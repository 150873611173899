/** @format */

import * as Sentry from "@sentry/browser";

let environment = document.head.getAttribute("data-environment");
let release = document.head.getAttribute("data-release");

Sentry.init({
    dsn:
        "https://2dcdf373741b4ee2a41b653fac30aea5@o309462.ingest.sentry.io/4504233487564800",
    environment: environment,
    release: release,
    ignoreErrors: [
        "/^Cannot read properties of undefined (reading 'domInteractive')$/",
    ],
});
